<template>
  <b-card>
    <div class="d-flex justify-content-between mb-2">
      <span>
        <h3>Registrar nuevo Beneficio</h3>
      </span>
      <b-button size="sm" variant="warning" @click="goToHome"> <feather-icon icon="ArrowLeftIcon" size="16" />Regresar</b-button>
    </div>
    <ValidationObserver ref="createBenefit" v-slot="{ invalid }">
      <b-form @submit.prevent="save">
        <b-row>
          <b-col md="3">
            <ValidationProvider rules="required" name="codigo">
              <b-form-group label="Código" slot-scope="{ valid, errors }">
                <b-form-input
                  class="form-control"
                  type="text"
                  v-model="newBenefit.code"
                  :state="errors[0] ? false : valid ? true : null"
                >
                </b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="3">
            <ValidationProvider rules="required" name="nombre">
              <b-form-group label="Nombre" slot-scope="{ valid, errors }">
                <b-form-input
                  class="form-control"
                  type="text"
                  v-model="newBenefit.name"
                  :state="errors[0] ? false : valid ? true : null"
                >
                </b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="3">
            <ValidationProvider rules="required" name="nameEng">
              <b-form-group
                label="* Nombre en inglés"
                slot-scope="{ valid, errors }"
              >
                <b-form-input
                  class="form-control"
                  type="text"
                  v-model="newBenefit.nameEng"
                  :state="errors[0] ? false : valid ? true : null"
                >
                </b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="3">
            <ValidationProvider rules="required" name="Tipo Beneficio">
              <b-form-group label="Tipo Beneficio" slot-scope="{ valid, errors }">
                <b-form-select
                  :state="errors[0] ? false : valid ? true : null"
                  v-model="newBenefit.idTypeBenefit"
                >
                  <option :value="null">Tipo de beneficio</option>
                  <option
                    v-for="typeBenefit in typesBenefits"
                    :key="typeBenefit.id"
                    :value="typeBenefit.id"
                  >
                    {{ typeBenefit.name }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="3">
            <ValidationProvider rules="" name="Grupo Tipo Beneficio">
              <b-form-group
                label="Grupo Tipo Beneficio"
                slot-scope="{ valid, errors }"
              >
                <b-form-select
                  :state="errors[0] ? false : valid ? true : null"
                  v-model="newBenefit.idGroupBenefit"
                >
                  <option :value="null">Grupo tipo de beneficio</option>
                  <option
                    v-for="groupTypeBenefit in groupsTypesBenefits"
                    :key="groupTypeBenefit.id"
                    :value="groupTypeBenefit.id"
                  >
                    {{ groupTypeBenefit.name }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="3">
            <ValidationProvider rules="" name="Forma de pago">
              <b-form-group label="Forma de pago" slot-scope="{ valid, errors }">
                <b-form-select
                  :state="errors[0] ? false : valid ? true : null"
                  v-model="newBenefit.payment"
                >
                  <option :value="null">Forma de pago</option>
                  <option
                    v-for="fp in paymentMethods"
                    :key="fp.id"
                    :value="fp.id"
                  >
                    {{ fp.name }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="3">
            <ValidationProvider rules="required" name="origen">
              <b-form-group label="Origen" slot-scope="{ valid, errors }">
                <b-form-select
                  :state="errors[0] ? false : valid ? true : null"
                  v-model="newBenefit.origin"
                >
                  <option :value="null">Origen</option>
                  <option v-for="fp in origins" :key="fp.id" :value="fp.id">
                    {{ fp.name }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <hr>

        <b-row>
          <b-col md="1">
            <div class="float-left pt-1">
              <b-form-checkbox
                v-model="newBenefit.status"
                :value="true"
                :unchecked-value="false"
                class="custom-control-success"
              >
                Activo
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col md="">
            <div class="float-left pt-1">
              <b-form-checkbox
                class="custom-control-success"
                v-model="newBenefit.includeBalance"
                :value="true"
                :unchecked-value="false"
              >
                Incluir en el reporte de Saldo de Beneficios
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col md="">
            <div class="float-left pt-1">
              <b-form-checkbox
                class="custom-control-success"
                v-model="newBenefit.includeBooking"
                :value="true"
                :unchecked-value="false"
              >
                Incluir en la sección de booking
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col md="">
            <div class="float-left pt-1">
              <b-form-checkbox
                class="custom-control-success"
                v-model="newBenefit.visibleOnWeb"
                :value="true"
                :unchecked-value="false"
              >
                Visualizar en la Web de Propietarios
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col md="">
            <div class="float-left pt-1">
              <b-form-checkbox
                class="custom-control-success"
                v-model="newBenefit.includeContract"
                :value="true"
                :unchecked-value="false"
              >
                Incluir en el contrato
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col md="">
            <div class="float-left pt-1">
              <b-form-checkbox
                class="custom-control-success"
                v-model="newBenefit.includedInSubscription"
                :value="true"
                :unchecked-value="false"
              >
                Disponible para suscripción
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col md="12" v-if="newBenefit.idGroupBenefit == 1">
            <div class="float-left pt-2 mt-1">
              <label for="radio-options">Seleccione tipo de noches</label>
              <b-form-radio-group
                v-model="newBenefit.nightTypesBenefits"
                :options="options"
                name="radio-options"
                stacked
              />
            </div>
          </b-col>
        </b-row>

        <br />

        <b-row class="mt-1 mb-1 border rounded p-1">
          <b-col md="12">
            <div class="d-flex justify-content-between">
              <h4>Filtro de Productos</h4>
            </div>
          </b-col>

          <b-col md="3">
           <b-form-group label="Lista de categorias">
              <b-form-select v-model="categories">
                <option value="">Categorias</option>
                <option
                  v-for="cat in categoriesProducts"
                  :key="cat.id"
                  :value="cat.id"
                >
                  {{ cat.name }}
                </option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group label="Productos">
              <b-form-input
                class="form-control"
                type="text"
                v-model="nameProduct"
              >
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="3" v-if="dataBenefitsProducts.length">
            <b-form-group label="Productos">
              <b-input
                v-model="filterBenefitsProducts"
                placeholder="Busqueda por nombre en la tabla"
                class="mb-2"
              ></b-input>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <div class="d-flex justify-content-start">
              <b-form-group label="Acciones">
                <b-button
                  :disabled="dataBenefitsProducts.length == 0"
                  variant="warning"
                  class="mr-1"
                  @click="resetFilter()"
                  >Reset
                </b-button>
                <b-button
                  :disabled="categories == ''"
                  variant="primary"
                  @click="searchByFilter()"
                  >Buscar
                </b-button>
              </b-form-group>
            </div>
          </b-col>

          <b-col md="12">
            <div v-if="!isLoadingProducts && dataBenefitsProducts.length">
              <b-table
                responsive
                :items="dataBenefitsProducts"
                :fields="productFields"
                class="mb-0"
                show-empty
                sticky-header="20rem"
                empty-text="No hay registros para mostrar"
              >
                <template #cell(actions)="row">
                  <div>
                    <b-form-checkbox
                      v-model="row.item.status"
                      switch
                      inline
                      :value="true"
                      :unchecked-value="false"
                      @change="addProduct(row.item, $event)"
                      class="custom-control-success"
                    />
                  </div>
                </template>
              </b-table>
            </div>

            <div class="text-center mt-2" v-if="isLoadingProducts && !filterEmpty">
              <b-spinner/><br />
              <strong>Cargando Datos</strong>
            </div>

            <div class="text-center mt-2" v-else-if="filterEmpty && !isLoadingProducts">
              <b-alert show variant="danger">
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="InfoIcon"
                  /><span class="ml-25">No hay productos con beneficios.</span>
                </div>
              </b-alert>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="float-right">
              <b-button
                type="submit"
                class="btn-block"
                variant="primary"
                :disabled="invalid || isSavingBenefit"
              > <b-spinner small v-if="isSavingBenefit" /> Guardar
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { showAlertMessage } from "@/helpers/helpers";
import { origins } from "@/data/fivesClub";

import { createArraysToSaveProducts } from "@/helpers/fivesClubHelper";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
  },
  data() {
    return {
      newBenefit: {
        code: "",
        name: "",
        nameEng: "",
        idTypeBenefit: null,
        idGroupBenefit: null,
        payment: null,
        origin: null,
        nightTypesBenefits: null,
        includeBalance: false,
        includeBooking: false,
        includeContract: true,
        includedInSubscription: false,
        status: true,
        visibleOnWeb: false,
      },
      benefitsProducts: [],
      filterEmpty: false,
      product: [],
      categories: "",
      nameProduct: null,
      filterBenefitsProducts: "",
      isSavingBenefit: false,
      origins,
      beforeSearch: false,
      isLoadingProducts: false,
      options: [
        { text: 'Noches por Contrato', value: 'isNightsContract' },
        { text: 'Noches por Temporada Alta', value: 'isNightsTA' },
        { text: 'Noches Extra', value: 'isNightsXtra' }
      ],
      productFields: [
        { key: "detaildisplay", label: "Nombre", class: "font-weight-bolder" },
        { key: "actions", label: "Usar", class: "" },
      ],
    };
  },
  computed: {
    ...mapState("fivesClubCatalogs", [
      "typesBenefits",
      "groupsTypesBenefits",
      "paymentMethods",
      "categoriesProducts",
    ]),
    dataBenefitsProducts() {
      return this.benefitsProducts
    },
    ...mapState("auth", ["user"]),
  },
  methods: {
    ...mapActions("fivesClubCatalogs", [
      "saveBenefit",
      "fetchBenefits",
      "fetchBenefitsProducts",
    ]),
    ...mapMutations("fivesClubCatalogs", [
      "setBenefits",
      "addBenefitsProducts",
    ]),

    async save() {
      const prId = createArraysToSaveProducts(this.product);
      this.isSavingBenefit = true;
      const payload = {
        ...this.newBenefit,
        idUser: this.user.idUser,
        product: prId,
      }
      try {
        const { status, message } = await this.saveBenefit(payload);
        if (status) {
          showAlertMessage("Ok: proceso finalizado", "InfoIcon", message, "success", 4000, "bottom-right");
          Object.assign(this.$data, this.$options.data());
          this.$refs.createBenefit.reset(); //reseteo form
          //vuelvo a pedir la info del back
          this.resetFilter()
          this.goToHome()
        }
      } catch (error) {
        showAlertMessage("Error", "InfoIcon", `Hubo un error al crear beneficio, intente más tarde. ${error}`, "danger", 4000, "bottom-right")
      }

      this.isSavingBenefit = false;
    },

    async searchByFilter() {
      this.isLoadingProducts = true;
      this.benefitsProducts = []
      this.filterEmpty = false
      const payload = { name: this.nameProduct, categories: this.categories };
      if (!payload.name) {
        delete payload.name;
      }
      const listProducts = await this.fetchBenefitsProducts(payload);
      this.benefitsProducts = listProducts.map(prods => {
        return {
          ...prods,
          status: false
        }
      })

      this.filterEmpty= this.benefitsProducts.length == 0
      this.isLoadingProducts = false;
    },
    resetFilter() {
      this.benefitsProducts = [];
      this.nameProduct = null;
      this.filterEmpty = false
      this.categories = "";
    },
    addProduct(option, e) {
      const bool = e == true ? true : false;
      if (bool == true) {
        this.product.push(option);
      } else {
        this.product = this.product.filter((b) => b.id !== option.id);
      }
    },
    goToHome(){
      this.$router.push({ name: 'benefits' })
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
tr.activeProdut {
  background: coral;
}
</style>